div.pause_wrapper {
  position: relative;
}

div.pause_post {
  overflow-wrap: anywhere;
}

div.pause_post a {
  color: #135589;
  font-weight: normal;
  text-decoration: none;
}

div.pause_post hr {
  border: 1px solid #333;
  width: 20%;
  margin: 30px 0 30px 0;
}

div.pause_header {
  font-size: 40px;
  padding: 8px 8px 8px 0;
}

div.pause_subtitle {
  font-size: 20px;
  padding: 8px 8px 20px 0;
}

div.pause_image {
  background-color: #533a16;
  background-size: cover;
  background-blend-mode: lighten;
  background-position: center;
  height: 300px;
  min-width: 100%;
}

@media only screen and (min-width: 768px) {
  div.pause_image {
    background-color: #533a16;
    background-size: cover;
    background-blend-mode: lighten;
    min-width: 0;
    width: 300px;
    height: 300px;
  }
}

div.pause_logo {
  padding-bottom: 16px;
}

div.pause_logo_1 {
  float: left;
  width: 5px;
  height: 14px;
  transform: skew(-0deg);
  background: #333;
  margin-right: 3px;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*optional bg color*/
  background-color: #eeeeeeee;
  /*the following isn't necessary but will help center everything once we add buttons*/
  display: flex;
  align-items: center;
  justify-content: center;
}

#lightbox-img {
  max-width: 90%;
  max-height: 90%;
}

.pause_loading {
  position: absolute;
  top: 0;
  left: 0;
  float: left;
}