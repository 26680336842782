body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: #135589;
  text-decoration: none;
}

.App {
  text-align: left;
  padding: 16px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: left;
}

.App-link {
  color: #61dafb;
}

.header {
  padding: 0 0 1em 0;
}

@media only screen and (min-width: 768px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .header_title {
    padding: 0;
    line-height: 70px;
  }

  ul.header_nav {
    line-height: 70px;
  }
}

.header_title {
  font-size: 60px;
  font-weight: bold;
  /* border: 1px solid black; */
}

.header_title a {
  color: #fff;
  text-decoration: none;
  margin: 0;
  padding: 8px 12px;
  display: inline-block;
  background-color: #999;
}

/* .header_title a.active {
  color: #135589;
  background: -webkit-linear-gradient(#2a93d5, #135589 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 1px solid #3abeff;
} */

.header_title a.active {
  background-color: #135589;
  /* text-decoration: underline; 
  border-bottom: 1px solid #3abeff; */
}

ul.header_nav {
  padding: 0;
  margin: 0;
  /* border: 1px solid black; */
}

ul.header_nav li {
  display: inline;
  list-style-type: none;
  margin: 0;
}

ul.header_nav li a {
  color: #666;
  font-weight: bold;
  text-decoration: none;
  padding: 8px 12px;
  display: inline-block;
}

ul.header_nav li a.active {
  color: #fff;
  background-color: #135589;
  /* text-decoration: underline; */
  /* border-bottom: 1px solid #3abeff; */
}

p {
  text-align: justify;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.loading {
  margin: 15px 0 15px 0;
  font-size: 15px;
  text-align: justify;
  opacity: 0.5;
  /* width: 16px;
  height: 16px;
  background-color: gray;
  border-radius: 100%;
  animation: loading-dot 1.2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955); */
}

.loading>div {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  background-color: gray;
  border-radius: 100%;
  display: inline-block;
  animation: loading-dot 1.2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@keyframes loading-dot {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.loading .loading_1 {
  animation-delay: -0.32s;
}

.loading .loading_2 {
  animation-delay: -0.16s;
}

p {
  line-height: 2;
  margin-bottom: 1.5em;
}

@media only screen and (min-width: 768px) {
  .App {
    width: 50%;
    margin: auto;
  }
}

ol {
  margin-left: 0;
  padding-left: 0;
}

ol li {
  margin-left: 1em;
  margin-bottom: 1.5em;
  padding-left: 0.5em;
  line-height: 2;
}