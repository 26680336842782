div.pauses_wrapper {
  position: relative;
}

div.pause_list_item {
  margin: 0 0 30px 0;
}

div.pause_list_item_title {
  font-size: 30px;
  font-weight: normal;
  margin-top: 10px;
}

div.pause_list_item_image {
  background-color: #533a16;
  background-size: cover;
  background-blend-mode: lighten;
  width: 150px;
  height: 150px;
}

div.pause_list_item a {
  color: #135589;
  text-decoration: none;
}

.pauses_loading {
  position: absolute;
  top: 0;
  left: 0;
  float: left;
}