.publications_list {
  clear: both;
}

.publication {
  padding: 8px 0 8px 0;
  text-align: left;
}

.publication_title {
  font-size: 30px;
}

.publication_year {
  padding: 4px 0 0 0;
  font-weight: normal;
}

.publication_link {
  padding: 4px 0 0 0;
}

.publication a {
  color: #135589;
  font-weight: bold;
  text-decoration: none;
  padding-right: 6px;
}

.publication_press_links {
  margin-bottom: 1em;
}

.publication_press_links a {
  color: #135589;
  font-weight: normal;
  text-decoration: none;
  padding-right: 0;
}

.publication_press_links .title {
  font-weight: bold;
}

.publications_header {
  clear: both;
}

.sortOptions {
  float: left;
  text-align: left;
  padding: 0 0 8px 0;
}

.sortOptions button {
  color: #135589;
  font-size: 20px;
  padding: 0;
  border: 0;
}

.sortOptions select {
  display: inline;
  font-size: 20px;
  margin: 0 10px 0 0;
  border: 0;
  padding: 8px 32px 8px 8px;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23333333%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #f0f0f0 0%, #f0f0f0 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}

.publications_loading {
  float: left;
  margin-top: 10px;
}
